import { Stack, Typography } from '@mui/material';
import { MessageThreadListItem } from '../../messages/message-thread-list-item';
import { useNavigate } from 'react-router-dom';
import { sortByCreatedAt } from '../../../helpers/messages-sort';
import { useSessionUser } from '../../hooks/session/useSessionUser';
import { Role } from '../../enums/role.enum';

export const DealThreads = ({ deal }) => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;

  const handleThreadClick = (threadId) => {
    navigate('/messages?tid=' + threadId);
  };

  return (
    <>
      {deal?.threads?.length > 0 ? (
        deal?.threads
          .sort(sortByCreatedAt)
          .slice(0, 5)
          .map((thread, index) => {
            const goToReply = (e) => {
              handleThreadClick(thread.externalId);
            };

            const isLast = index === deal.threads.length - 1;

            return (
              <Stack
                key={index}
                sx={{
                  borderBottom: !isLast ? '1px solid lightgrey' : '',
                }}
              >
                <MessageThreadListItem
                  thread={thread}
                  deal={deal}
                  organization={deal.organization}
                  onClick={!isSysAdmin ? goToReply : undefined}
                />
              </Stack>
            );
          })
      ) : (
        <Typography variant="body2" color="textSecondary">
          No messages for this deal yet.
        </Typography>
      )}
    </>
  );
};
