import {
  differenceInCalendarDays,
  differenceInHours,
  formatDistanceStrict,
} from 'date-fns';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { MessageThreadHeader } from './message-thread-header';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { localTime } from '../../helpers/dates';
import { info } from '../theme/colors';
import { Reply } from '@mui/icons-material';

export const MessageThreadListItem = ({
  thread,
  deal,
  organization,
  onClick,
  isSelected,
}) => {
  const { sessionUser } = useSessionUser();

  const now = new Date();
  const sentAtLocal = localTime(thread.createdAt);
  const daysSinceSent = differenceInCalendarDays(now, sentAtLocal);
  const hoursSinceSent = differenceInHours(now, sentAtLocal);
  const formatSentAt = formatDistanceStrict(sentAtLocal, now, {
    unit: hoursSinceSent <= 1 ? 'minute' : daysSinceSent <= 1 ? 'hour' : 'day',
  });

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick({ thread });
    }
  };

  const latestMessage = thread.messages[thread.messages.length - 1];
  const user = latestMessage.fromUser;

  const isRead = latestMessage.reads?.length > 0 || user.id === sessionUser?.id;
  const firstName =
    sessionUser?.id === user.id ? 'You' : user.name?.split(' ')[0] || user.name;

  return (
    <Stack
      sx={{
        px: 2,
        py: 1.5,
        opacity: isRead ? 0.7 : 1,
        backgroundColor: !isSelected ? '' : info.lightest,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={handleClick}
    >
      <Stack spacing={1}>
        <MessageThreadHeader
          thread={thread}
          deal={deal}
          organization={organization}
          onClick={onClick}
          isSidebar
        />
        <Typography
          variant={'body2'}
          color={'text.secondary'}
          sx={{
            pl: 8,
          }}
        >
          {firstName}: {latestMessage.body}
        </Typography>
      </Stack>
    </Stack>
  );
};
