import { Avatar, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import { OrganizationLink } from '../organizations/organization-link';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { StorageBucket } from '../../helpers/supabase';
import { localTime } from '../../helpers/dates';
import { Role } from '../enums/role.enum';
import { QualificationBadges } from '../users/qualification-badges';
import { Reply } from '@mui/icons-material';

export const MessageThreadHeader = ({
  thread,
  deal,
  organization,
  isSidebar,
  onClick,
}) => {
  const { sessionUser } = useSessionUser();

  const hasOrgLogo = organization?.logoImage;
  const orgLogo =
    hasOrgLogo &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.ORGANIZATION_IMAGES}/${organization.id}/${organization.logoImage}`;

  const latestMessage = thread.messages[thread.messages.length - 1];
  const isRead =
    latestMessage.reads?.length > 0 ||
    latestMessage.fromUser.id === sessionUser?.id;
  const isInvestor = sessionUser?.role === Role.Investor;
  const msgCreateAtLocal = localTime(latestMessage.createdAt);
  const latestMessageFromUser = latestMessage.fromUser;
  const latestMessageFromUserName =
    latestMessageFromUser.id === sessionUser?.id
      ? 'You'
      : latestMessageFromUser.name.split(' ')[0];

  const name = isInvestor ? organization.name : thread.user.name;

  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Avatar sx={{ height: 50, width: 50, mb: 3 }} src={orgLogo}>
          {name.substring(0, 2).toUpperCase()}
        </Avatar>
        <Stack>
          {isInvestor ? (
            <Typography variant={'h6'}>
              {!isRead ? <b>{deal.name}</b> : deal.name}
            </Typography>
          ) : (
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant={'h6'}>
                <b>{thread.user.name}</b>
              </Typography>
              <QualificationBadges
                qualifications={thread.user.qualification}
                iconsOnly={isSidebar}
              />
            </Stack>
          )}
          {!isInvestor ? (
            <Typography variant={'body2'}>{deal.name}</Typography>
          ) : (
            <OrganizationLink organization={organization} deal={deal} />
          )}
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        justifyContent={'flex-end'}
      >
        {isSidebar && (
          <Typography variant={'caption'}>
            Sent {format(msgCreateAtLocal, 'MMM d')}
          </Typography>
        )}
        {onClick && (
          <Tooltip title={'Reply'}>
            <IconButton onClick={onClick}>
              <Reply fontSize="small" color={'primary'} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};
