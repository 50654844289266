import React, { useRef } from 'react';
import { Box } from '@mui/material';
import Photo from '@mui/icons-material/Photo';
import { StorageBucket } from '../../../helpers/supabase';
import { neutral } from '../../theme/colors';
import { CustomImageGallery } from '../../images/image-gallery';

const IMG_WIDTH = 458;
const IMG_HEIGHT = 275;

export const DealImages = ({ deal, width, height, isSponsorPage = false }) => {
  const galleryRef = useRef();

  const galleryImages = deal?.images?.length
    ? deal?.images?.map((img) => {
        const imageSrc = `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${deal.id}/${img.name}`;
        return {
          original: `${imageSrc}`,
          thumbnail: `${imageSrc}`,
          fullscreen: `${imageSrc}`,
        };
      })
    : null;

  const hasImg = galleryImages?.length;

  return (
    <Box sx={{ mb: 4 }}>
      {hasImg ? (
        <CustomImageGallery
          galleryRef={galleryRef}
          images={galleryImages}
          showThumbnails={!isSponsorPage}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: neutral[200],
            borderRadius: 5,
          }}
        >
          <Photo sx={{ width: IMG_WIDTH, height: IMG_HEIGHT }} />
        </div>
      )}
    </Box>
  );
};
