import React from 'react';
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from '@mui/material';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import { Star, StarBorder } from '@mui/icons-material';

const IMG_SIZE = 164;

export const ImageLibrary = ({
  images,
  imgSrcs,
  onClickDelete,
  onChangePriority,
}) => {
  const isMultiImage = images?.length > 1;

  return (
    <ImageList cols={3}>
      {imgSrcs.map((src, idx) => {
        const handleDelete = (e) => {
          e.stopPropagation();
          onClickDelete(idx);
        };

        const isPriority = images[idx].isPriority;

        const handleChangePriority = (e) => {
          e.stopPropagation();
          onChangePriority(idx, !isPriority);
        };

        return (
          <ImageListItem key={src}>
            <img
              src={`${src}`}
              srcSet={`${src}`}
              alt={'deal image'}
              loading="lazy"
            />
            <ImageListItemBar
              title={''}
              subtitle={''}
              actionIcon={
                <>
                  {isMultiImage && !isPriority && (
                    <Tooltip title={'Prioritize this image'}>
                      <IconButton
                        onClick={handleChangePriority}
                        sx={{
                          color: 'rgba(255, 255, 255, 0.8)',
                        }}
                      >
                        <StarBorder />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isMultiImage && isPriority && (
                    <Tooltip title={'Do not prioritize this image'}>
                      <IconButton
                        onClick={handleChangePriority}
                        sx={{
                          color: 'rgba(255, 255, 255, 0.8)',
                        }}
                      >
                        <Star />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={'Remove image'}>
                    <IconButton
                      onClick={handleDelete}
                      sx={{
                        color: 'rgba(255, 255, 255, 0.8)',
                      }}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};
